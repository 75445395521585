/* This css contains the minimal styles to bootstrap the app in a sane state
 * before the theme is loaded.
 */

html, body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "ContinentalStagSansA", "Arial", "Helvetica", sans-serif; /* fontFamily from theme */
  font-weight: 300; /* default is 400 but ContinentalStagSansA has only 300 */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000; /* background.default from theme */
  color: #ffffff; /* primary text color white, so things are visible */
}

#root {
  height: 100%;
  display: flex;
}

#font-preloader {
  position: absolute;
  top: -600px;
  font-weight: 300; /* fontWeightRegular from theme */
  color: #000000; /* background.default from theme */
  font-family: "ContinentalStagSansA", "Arial", "Helvetica", sans-serif; /* fontFamily from theme */
}

#font-preloader > span {
  font-weight: 500; /* fontWeightMedium from theme */
}

#bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-color: #000000; /* background.default from theme */
  background-image: url(../src/images/app-icon.svg);
  background-repeat:no-repeat;
  background-position: center center;
  background-size: auto 30%;
}

#loader {
  position: absolute;
  bottom: 8%;
  left: 50%;
  font-weight: 500;
  font-family: "ContinentalStagSansA", "Arial", "Helvetica", sans-serif; /* fontFamily from theme */
  color: white;
  transform: translate(-50%, -50%);
}

#loader > button {
  background: transparent;
  border: none;
  font-weight: inherit;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  color: #ffa500 /* continentalYellow500 */
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px #1a1a1a inset;
  -webkit-text-fill-color: #ffffff;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #000000;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #000000;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #666666;
  border-radius: 10px;
}

