body .rc-calendar {
  width: 253px;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: #000;
  border-radius: 3px;
  box-shadow: none;
  background-clip: padding-box;
  border: 1px solid #969696;
  line-height: 1.5;
}
body .rc-calendar-header {
  height: 41px;
  border-bottom: 1px solid #969696;
}
body .rc-calendar-header .rc-calendar-prev-month-btn:after {
  font-size: 40px;
  content: '‹';
}
body .rc-calendar-header .rc-calendar-next-month-btn {
  position: absolute;
  right: 40px;
}
body .rc-calendar-header .rc-calendar-next-month-btn:after {
  font-size: 40px;
  content: '›';
}
body .rc-calendar-next-year-btn:after {
  font-size: 40px;
  content: '»';
}
body .rc-calendar-prev-year-btn:after {
  font-size: 40px;
  content: '«';
}
body .rc-calendar-year-select,
body .rc-calendar-month-select,
body .rc-calendar-day-select {
  font-size: 20px;
  color: #fff;
  line-height: 40px;
}
body .rc-calendar-year-select:hover,
body .rc-calendar-month-select:hover,
body .rc-calendar-day-select:hover {
  cursor: pointer;
  color: #ffa500;
}
body .rc-calendar-year-select.rc-calendar-time-status:hover,
body .rc-calendar-month-select.rc-calendar-time-status:hover,
body .rc-calendar-day-select.rc-calendar-time-status:hover {
  cursor: pointer;
  color: #ffa500;
}
body .rc-calendar-prev-month-btn:hover,
body .rc-calendar-next-month-btn:hover,
body .rc-calendar-prev-year-btn:hover,
body .rc-calendar-next-year-btn:hover {
  color: #ffa500;
}
body .rc-calendar-date {
  color: #fff;
}
body .rc-calendar-date:hover {
  background: #00a5dc;
  cursor: pointer;
}
body .rc-calendar-selected-day .rc-calendar-date {
  background: #00a5dc;
}

body .rc-calendar-event-pending-day .rc-calendar-date {
  color: #000;
  background: #ffa500;
}
body .rc-calendar-event-registered-day .rc-calendar-date {
  color: #000;
  background: #2db928;
}
body .rc-calendar-event-declined-day .rc-calendar-date {
  color: #000;
  background: #ff2d37;
}
body .rc-calendar-selected-date .rc-calendar-date {
  background: #00a5dc;
  color: #fff;
}
body .rc-calendar-selected-date .rc-calendar-date:hover {
  background: #00a5dc;
}
body .rc-calendar-today .rc-calendar-date {
  border: 1px solid #00a5dc;
}
body .rc-calendar-last-month-cell .rc-calendar-date,
body .rc-calendar-next-month-btn-day .rc-calendar-date {
  color: #737373;
}
body .rc-calendar-today-btn,
body .rc-calendar-ok-btn,
body .rc-calendar-time-picker-btn {
  color: #ffa500;
}
body .rc-calendar-month-panel {
  background: #000;
  font-size: 18px;
}
body .rc-calendar-month-panel-header {
  height: 41px;
  border-bottom: 1px solid #969696;
}
body .rc-calendar-month-panel-header > a {
  font-weight: normal;
  padding: 0px 0px;
  line-height: 30px;
}
body .rc-calendar-month-panel-header > a:hover {
  cursor: pointer;
  color: #ffa500;
}
body .rc-calendar-month-panel-prev-year-btn {
  position: absolute;
  left: 7px;
  top: 2px;
  color: #969696;
}
body .rc-calendar-month-panel-next-year-btn {
  position: absolute;
  right: 11px;
  top: 2px;
  color: #969696;
}
body .rc-calendar-month-panel-next-year-btn:after {
  font-size: 40px;
  content: '»';
}
body .rc-calendar-month-panel-prev-year-btn:after {
  font-size: 40px;
  padding-left: 8px;
  content: '«';
}
body .rc-calendar-month-panel .rc-calendar-month-panel-year-select {
  width: 180px;
  font-size: 20px;
  padding-top: 4px;
  font-weight: bold;
}
body .rc-calendar-month-panel-cell .rc-calendar-month-panel-month {
  color: #fff;
}
body .rc-calendar-month-panel-cell .rc-calendar-month-panel-month:hover {
  background: #00a5dc;
  cursor: pointer;
}
body .rc-calendar-month-panel-selected-cell .rc-calendar-month-panel-month {
  background: #00a5dc;
  color: #fff;
}
body .rc-calendar-month-panel-selected-cell .rc-calendar-month-panel-month:hover {
  background: #00a5dc;
  color: #fff;
}
body .rc-calendar-year-panel {
  background: #000;
  font-size: 18px;
}
body .rc-calendar-year-panel-header {
  height: 41px;
  border-bottom: 1px solid #969696;
}
body .rc-calendar-year-panel-header > a {
  font-weight: normal;
  padding: 0px 0px;
}
body .rc-calendar-year-panel-header > a:hover {
  cursor: pointer;
  color: #ffa500;
}
body .rc-calendar-year-panel-prev-decade-btn {
  position: absolute;
  top: 2px;
  left: 7px;
  color: #969696;
}
body .rc-calendar-year-panel-next-decade-btn {
  position: absolute;
  top: 2px;
  right: 11px;
  color: #969696;
}
body .rc-calendar-year-panel-prev-decade-btn:after {
  font-size: 40px;
  content: '«';
}
body .rc-calendar-year-panel-next-decade-btn:after {
  font-size: 40px;
  content: '»';
}
.rc-calendar-year-panel .rc-calendar-year-panel-decade-select {
  width: 180px;
  font-size: 20px;
  padding-top: 4px;
  font-weight: bold;
}
body .rc-calendar-year-panel-year {
  color: #fff;
}
body .rc-calendar-year-panel-year:hover {
  background: #00a5dc;
  cursor: pointer;
}
body .rc-calendar-year-panel-selected-cell .rc-calendar-year-panel-year {
  background: #00a5dc;
  color: #fff;
}
body .rc-calendar-year-panel-selected-cell .rc-calendar-year-panel-year:hover {
  background: #00a5dc;
  color: #fff;
}
body .rc-calendar-year-panel-last-decade-cell .rc-calendar-year-panel-year,
body .rc-calendar-year-panel-next-decade-cell .rc-calendar-year-panel-year {
  color: #737373;
}
body .rc-calendar-decade-panel {
  background: #000;
  font-size: 20px;
  font-weight: bold;
}
body .rc-calendar-decade-panel-header {
  padding: 4px 10px;
  height: 41px;
  line-height: 30px;
  border-bottom: 1px solid #969696;
}
body .rc-calendar-decade-panel-header > a {
  font-weight: normal;
  padding: 0px 0px;
}
body .rc-calendar-decade-panel-header > a:hover {
  cursor: pointer;
  color: #ffa500;
}
body .rc-calendar-decade-panel-prev-century-btn {
  position: absolute;
  top: 2px;
  left: 7px;
  color: #969696;
}
body .rc-calendar-decade-panel-next-century-btn {
  position: absolute;
  top: 2px;
  right: 11px;
  color: #969696;
}
body .rc-calendar-decade-panel-next-century-btn:after {
  font-size: 40px;
  content: '»';
}
body .rc-calendar-decade-panel-prev-century-btn:after {
  font-size: 40px;
  content: '«';
}
body .rc-calendar-decade-panel-decade {
  color: #fff;
  font-size: 18px;
  font-weight: normal;
}
body .rc-calendar-decade-panel-decade:hover {
  background: #00a5dc;
  cursor: pointer;
}
body .rc-calendar-decade-panel-selected-cell .rc-calendar-decade-panel-decade {
  background: #00a5dc;
  color: #fff;
}
body .rc-calendar-decade-panel-selected-cell .rc-calendar-decade-panel-decade:hover {
  background: #00a5dc;
  color: #fff;
}
body .rc-calendar-decade-panel-last-century-cell .rc-calendar-decade-panel-decade,
body .rc-calendar-decade-panel-next-century-cell .rc-calendar-decade-panel-decade {
  color: #737373;
}

body .react-datepicker {
  background-color: black;
  border-color: white;
}

body .react-datepicker-wrapper {
  flex: 1;
  height: 32px;
}

body .react-datepicker__header {
  background-color: black;
}

body .react-datepicker__current-month {
  color: white;
}

body .react-datepicker__day-name {
  color: white;
}

body .react-datepicker__month {
  background-color: black;
  border-color: black;
}

body .react-datepicker__day {
  color: white;
}

body .react-datepicker__day--keyboard-selected {
  background-color: #ffa500;
}

body .react-datepicker__day--selected {
  background-color: #ffa500;
}

body .react-datepicker__day:hover {
  background-color: #ffa500;
}

body .react-datepicker__today-button {
  background-color: black;
  color: white;
}
